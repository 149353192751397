<template>
  <header
    id="header-nav"
    ref="header"
    data-scroll-target="#root-container"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    }
    ${sticky ? 'sticky' : ''}
    `">
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a href="/">
            <transition
              name="fade"
              mode="out-in">
              <template v-if="device === 'desktop'">
                <img
                  v-if="site.site_logo?.url && !sticky"
                  :src="site.site_logo?.url || ''"
                  alt="site-logo"
                  @click="close(true)" />
                <img
                  v-else
                  :src="site.site_logo_sticky?.url || ''"
                  alt="site-logo"
                  @click="close(true)" />
              </template>
              <template v-else>
                <img
                  :src="site.site_logo_sticky?.url || ''"
                  alt="site-logo"
                  @click="close(true)" />
              </template>
            </transition>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-three" />
        </div>
      </div>
      <div
        class="nav-container-wrapper"
        :style="{ backgroundImage: `url(${bgImage})` }">
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in menu.menuItems.nodes"
              :key="item.id"
              tag="li"
              appear
              class="nav-container-items">
              <NavMenuItem
                :key="item.id"
                :data-ind="index"
                :item="item"
                :class="item.cssClasses"
                :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                @click="close(true)" />
            </transition-group>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import NavMenuItem from '@/components/template-parts/NavMenu/NavMenuItem.vue'
import { ref, watch } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'
import useIntroShown from '@/composables/useIntroShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'
import bgImage from '@/assets/img/mobile-menu-bg.jpg'

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref(false)
const navOpen = ref()
const transitioning = ref(false)
const { scrollY, device } = useUtils()
const { introShown } = useIntroShown()
const { smoothScroll } = useSmoothScroll()

watch(scrollY, (newVal) => {
  if (newVal > 0) {
    // uncomment for sticky header
    // sticky.value = true
  } else {
    sticky.value = false
  }
})

const toggle = () => {
  navOpen.value = !navOpen.value
}
const close = (data) => {
  if (data !== undefined) navOpen.value = !data
  else navOpen.value = !navOpen.value
}

watch(
  () => navOpen.value,
  (newVal) => {
    transitioning.value = true
    if (newVal && device.value !== 'desktop') {
      smoothScroll.stop()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (!newVal && device.value !== 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 0.5,
        opacity: 0,
        stagger: 0.1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    }
  }
)
</script>

<style lang="scss">
#header-nav.mobile {
  @apply top-0 z-[41];
  nav {
    @apply bg-transparent;
  }
  .nav-container-wrapper {
    @apply -z-1 opacity-0 top-full origin-top h-screen absolute left-0 w-screen transition-all duration-1000 delay-700 ease-in-out;
    @apply bg-no-repeat bg-center bg-cover pointer-events-none;
    .nav-container {
      @apply pt-24 w-[96%] mx-auto;
      .nav-container-items {
        @apply bg-transparent opacity-0 pointer-events-none;
      }
    }
  }
}

#header-nav {
  @apply w-full absolute lg:px-5;
  .logo-container img {
    @apply w-44 xl:w-52;
  }
  .nav-container-wrapper {
    @apply w-full lg:h-full transition-all;
    @include desktop {
      background-image: none !important;
    }
  }
}
#header-nav.sticky {
  @apply fixed;
}
#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px];
  .line-one {
    @apply transform rotate-45 translate-y-4 translate-x-[0px];
  }
  .line-three {
    @apply transform -rotate-45 -translate-y-[-4px] -translate-x-[0px];
  }
  .nav-container-items {
    pointer-events: all !important;
  }

  .nav-container-wrapper {
    @apply opacity-100 h-screen top-0 delay-0;
  }
}
.header-nav {
  @apply flex mt-0 mb-0 items-stretch
  justify-center w-[100vw] top-0 min-h-[70px]
  bg-transparent lg:overflow-visible 
  max-h-[80px] lg:max-h-[110px] z-10;
  nav {
    @apply flex flex-col lg:flex-row lg:justify-between items-stretch lg:h-[110px] lg:max-h-[110px]
    lg:items-center w-full max-w-[1400px] py-3 px-1 lg:py-3 lg:px-6 xl:py-0 xl:px-12
    transition-all duration-300 ease-in-out border-b border-b-lm_white mx-2 lg:mx-0;
    .nav-container {
      @apply flex flex-col my-0 lg:flex-row lg:gap-9 items-start lg:items-end md:px-0 xl:px-0;
      li {
        @apply w-full lg:w-auto text-center;
      }
    }
  }
  .branding {
    @apply w-full lg:w-[30%] xl:w-[35%] text-center
    md:text-left flex items-center lg:max-h-[65px]
    justify-between;
    .logo-container {
      @apply h-full xl:pl-2 xl:max-h-full flex items-center lg:inline-block;
      img {
        @apply w-[80%] lg:w-auto h-full max-h-[60px] lg:max-h-[80px];
      }
    }
    .mobile-nav {
      @apply flex flex-col lg:hidden float-right p-3 pt-3 pb-2 w-fit;
      .line {
        @apply relative mb-[10px] transition-all duration-[0.5s] ease-in-out;
        &.line-three {
          @apply -translate-x-3;
        }
      }
      .line:before {
        @apply content-[''] block h-[2px] w-[37px] bg-white;
      }
    }
  }

  .nav-container {
    @apply w-full h-full flex text-center md:text-left transition duration-500 ease-in-out m-0 justify-start lg:justify-end;
    .nav-container-items {
      @apply w-full h-20 lg:h-full flex items-center lg:w-auto text-center;
      div.menu-items {
        @apply h-full flex justify-center items-center;
        a {
          @apply h-fit w-full text-left lg:small;
          line-height: 22px;
          @include mobile {
            line-height: 50px;
          }
        }
      }
    }
  }
}
</style>
