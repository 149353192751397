<template>
  <footer
    v-if="!store.site.loading && acf_options"
    class="site-footer">
    <div class="gutter-wrapper">
      <div class="footer-container">
        <div class="left-side">
          <div class="footer-col-1">
            <div class="logo">
              <quick-link
                :to="'/'"
                title="Home">
                <img
                  v-if="acf_options.site_logo_footer"
                  class="h-auto max-h-[80px] w-auto mb-8"
                  :src="acf_options.site_logo_footer.url"
                  :alt="acf_options.site_logo_footer.alt" />
              </quick-link>
              <div class="hidden lg:flex flex-row gap-x-4 ml-0 lg:ml-[60px]">
                <a
                  v-if="acf_options.X"
                  :href="acf_options.X"
                  target="_blank">
                  <img
                    src="@/assets/img/x.svg"
                    alt="x" />
                </a>
                <a
                  v-if="acf_options.linkedin"
                  :href="acf_options.linkedin"
                  target="_blank">
                  <img
                    src="@/assets/img/linkedin.svg"
                    alt="linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="flex justify-between flex-col lg:flex-row">
            <div class="footer-col-1">
              <FooterMenu name="footer-menu-column-1" />
            </div>
            <div class="footer-col-2">
              <FooterMenu name="footer-menu-column-2" />
            </div>
            <div class="footer-col-3">
              <div class="social-media">
                <div class="flex-row gap-x-4 flex lg:hidden">
                  <a
                    v-if="acf_options.X"
                    :href="acf_options.X"
                    target="_blank">
                    <img
                      src="@/assets/img/x.svg"
                      alt="x" />
                  </a>
                  <a
                    v-if="acf_options.linkedin"
                    :href="acf_options.linkedin"
                    target="_blank">
                    <img
                      src="@/assets/img/linkedin.svg"
                      alt="linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <p class="rights text-left caption pb-12">
            Copyright © {{ currentYear }} LifeMine Therapeutics |
            <template
              v-for="(link, indx) of acf_options.footer_links"
              :key="`footer-link-${indx}`">
              <a
                :href="link.link.url"
                :target="link.link.target"
                class="mr-1"
                >{{ link.link.title }}</a
              >
              {{ indx < acf_options.footer_links.length - 1 ? ' | ' : '' }}
            </template>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeMount } from 'vue'

const store = useStore()
const acf_options = ref(null)
const currentYear = computed(() => {
  return new Date().getFullYear()
})

onBeforeMount(() => {
  acf_options.value = store.acfOptions()
})
</script>

<style lang="scss">
.site-footer {
  @apply module-container bg-lm_white pt-16;
  .gutter-wrapper {
    @apply inner-container px-6 2xl:px-0;
    .footer-container {
      @apply flex flex-col lg:flex-row justify-between gap-y-4;
      .left-side {
        .footer-col-1 {
          @apply flex flex-col pb-6 mb-0 lg:border-b-0;
          .logo {
            @apply mb-0 lg:mb-16;
          }
        }
      }
      .right-side {
        @apply flex flex-col gap-x-36 gap-y-10 w-full lg:w-7/12;
        .footer-col-2 {
          @apply flex flex-col lg:flex-row border-b border-lm_lavender pb-0 mb-0 lg:border-b-0;
          h3 {
            @apply font-normal text-[22px] mb-4;
          }
          p {
            @apply font-normal caption mb-14;
          }
        }
        .footer-col-3 {
          @apply flex flex-col py-8 lg:py-0  border-b border-lm_lavender lg:border-b-0;
          h3 {
            @apply font-normal text-[22px] mb-0 lg:mb-4;
          }
          p {
            @apply font-normal caption mb-9;
          }
          .social-media {
            @apply flex flex-row lg:flex-col;
            a {
              @apply mr-4;
            }
          }
        }
      }
    }
  }
}
</style>
