<template>
  <div
    id="website-wrapper"
    @click="handleClicks">
    <transition
      name="fade"
      mode="out-in">
      <SiteIntro v-if="!introShown" />
    </transition>
    <div
      ref="pageTransition"
      class="page-transition-block"></div>
    <transition name="fade">
      <SiteLoading v-if="queryLoading" />
    </transition>
    <nav-menu
      class="main-nav"
      name="main-menu" />
    <div id="website-content">
      <div class="web-inner-wrapper">
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            :css="false"
            @before-enter="onBeforeEnter"
            @enter="onEnter"
            @after-enter="onAfterEnter"
            @before-leave="onBeforeLeave"
            @leave="pageLeave"
            @after-leave="onAfterLeave">
            <component
              :is="Component"
              :key="$route.path" />
          </transition>
        </router-view>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script setup>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from '@/components/utility/SiteIntro.vue'
import { ref, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import emitter from './scripts/emitter'
import useIntroShown from './composables/useIntroShown.js'
// Uncomment the following line to enable smoothScroll
import useSmoothScroll from './composables/useSmoothScroll.js'
import gsap from 'gsap'
import useGQL from './composables/useGQL'
import useReady from './composables/useReady'

const router = useRouter()
const store = useStore()
const site = ref(store.site)
const showMenu = ref()
const transitioning = ref(false)
const pageLoaded = ref(false)
const { introShown } = useIntroShown()
const { queryLoading } = useGQL()
const { ready } = useReady()
const pageTransition = ref(null)
// Uncomment the following line to enable smoothScroll
const { smoothScroll } = useSmoothScroll()

const onBeforeEnter = () => {
  // console.log('beforeEnter')
}

const onEnter = (el, done) => {
  // console.log('page enter')
  gsap.set(el, { opacity: 0 })
  showMenu.value = true

  gsap.to(el, {
    duration: 0.3,
    delay: 0.3,
    opacity: 1,
    onComplete: () => {
      const waiter = () => {
        if (ready.value) {
          done()
        } else {
          setTimeout(() => {
            waiter()
          }, 100)
        }
      }
      waiter()
    },
  })
}

const onAfterEnter = () => {
  emitter.emit('afterPageEnter')
  // console.log('emit afterPageEnter')
  transitioning.value = false
  gsap.to(pageTransition.value, {
    duration: 1,
    transformOrigin: 'right',
    scaleX: 0,
    ease: 'power2.inOut',
  })
}

const onBeforeLeave = () => {
  // console.log('onBeforeLeave')
  gsap.fromTo(
    pageTransition.value,
    {
      transformOrigin: 'left',
      scaleX: 0,
    },
    {
      duration: 0.5,
      scaleX: 1,
      ease: 'power2.inOut',
    }
  )
}

const pageLeave = (el, done) => {
  // console.log('onLeave')

  transitioning.value = true
  gsap.to(el, {
    delay: 0.3,
    duration: 0.5,
    opacity: 0,
    onComplete: done,
  })
}

const onAfterLeave = () => {
  showMenu.value = false
}

const getLinkEl = (el) => {
  while (el.parentNode) {
    if (el.tagName === 'A') return el
    el = el.parentNode
  }
}

const handleClicks = (e) => {
  const a = getLinkEl(e.target)
  if (a && a.href.includes(site.value.url)) {
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = e
    // don't handle if has class 'no-router'
    if (a.className.includes('no-router')) return
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return
    // don't handle when preventDefault called
    if (defaultPrevented) return
    // don't handle right clicks
    if (button !== undefined && button !== 0) return
    // don't handle if `target="_blank"`
    if (a.target && a.target.includes('_blank')) return
    // don't handle same page links
    const currentURL = new URL(a.href, window.location.href)
    if (currentURL && currentURL.pathname === window.location.pathname) {
      // if same page link has same hash prevent default reload
      const currtURL = currentURL
      const windowLocation = window.location
      if (currtURL.hash === windowLocation.hash) e.preventDefault()
    }
    // Prevent default and push to vue-router
    e.preventDefault()
    const path = a.href.replace(site.value.url, '')
    router.push(path)
  }
}

const handleScroll = ({ el, offset, duration, immediate, lock }) => {
  if (pageLoaded.value) {
    if (!el) {
      smoothScroll.scrollTo('top', { immediate })
    } else if (el === 'top') {
      setTimeout(() => {
        smoothScroll.scrollTo('top', {
          offset,
          duration,
          immediate,
          onComplete: () => {
            // console.log('scroll complete')
          },
        })
      }, 500)
    } else {
      setTimeout(() => {
        smoothScroll.scrollTo(el, {
          offset,
          duration,
          immediate,
          onComplete: () => {
            // console.log('scroll complete')
          },
        })
      }, 100)
    }
  } else {
    setTimeout(() => {
      handleScroll({ el, offset, duration, immediate, lock })
    }, 100)
  }
}

const waitForIntro = () => {
  if (introShown.value) {
    pageLoaded.value = true
  } else {
    setTimeout(() => {
      waitForIntro()
    }, 100)
  }
}

onMounted(() => {
  gsap.set(pageTransition.value, {
    transformOrigin: 'left',
    scaleX: 0,
  })

  window.onload = () => {
    waitForIntro()
  }
  emitter.on('scrollNow', ({ el, offset, duration, immediate, lock }) => {
    // console.log('scrollNow event triggered')
    handleScroll({ el, offset, duration, immediate, lock })
  })
})
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Vue transition classes
-------------------------------------------- */

.web-inner-wrapper {
  @apply min-h-screen;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.4s ease-out;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#website-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

// Lenis Styles START
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
// Lenis Styles END

// Page Transition Styles
.page-transition-block {
  @apply fixed w-full h-full z-50 inset-0 bg-lm_purple pointer-events-none;
}
</style>
