<template>
  <div class="site-loading-wrap">
    <div class="site-loading"></div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.site-loading-wrap {
  @apply fixed top-0 bottom-0 left-0 right-0 z-40 lg:z-50 bg-white backdrop-blur-3xl;
}
</style>
