import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../scripts/emitter'
import { useStore } from '@/stores/main'

const { url } = window.__VUE_WORDPRESS__.routing
const { site } = window.__VUE_WORDPRESS__.state
// scroll position is handled in @after-leave transition hook
// if ('scrollRestoration' in window.history)
//   window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        if (savedPosition.top > 0) {
          emitter.emit('scrollNow', {
            el: savedPosition.top,
            offset: 0,
            duration: 1,
            immediate: false,
          })
        } else {
          emitter.emit('scrollNow', {
            el: 'top',
            immediate: true,
          })
        }
        resolve()
      })
    }
  },
})

function equalUrls(url1, url2) {
  const store = useStore()
  // remove trailing slash
  const from = url1.endsWith('/') ? url1.slice(0, -1) : url1
  const to = url2.endsWith('/') ? url2.slice(0, -1) : url2
  return (
    new URL(from, store.site.url).pathname ===
    new URL(to, store.site.url).pathname
  )
}

router.afterEach((to, from) => {
  if (to.hash && to.hash !== '#') {
    if (equalUrls(from.path, to.path)) {
      // console.log('same route navigation')
      // console.log('afterPageEnter listener')
      emitter.emit('scrollNow', {
        el: to.hash,
        offset: -90,
        duration: 1,
        lock: true,
        immediate: false,
      })
    } else {
      emitter.once('afterPageEnter', () => {
        // console.log('different route navigation')
        // console.log('afterPageEnter listener')
        setTimeout(() => {
          emitter.emit('scrollNow', {
            el: to.hash,
            offset: -90,
            duration: 1,
            immediate: false,
          })
        }, 10)
      })
    }
  } else {
    // console.log('no saved position')
    // console.log('afterPageEnter listener')
    emitter.emit('scrollNow', {
      el: 'top',
      immediate: true,
    })
  }

  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})
