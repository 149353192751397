import { ref } from 'vue'
import { useQuery, provideApolloClient } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { apolloClient } from '@/apolloClient'

export default function useGQL() {
  provideApolloClient(apolloClient)
  const queryLoading = ref(false)
  const gqlQuery = async (query) => {
    queryLoading.value = true

    return new Promise((resolve, reject) => {
      try {
        const { onResult, onError } = useQuery(gql`
          ${query}
        `)

        onResult((result) => {
          if (result.data) {
            queryLoading.value = false
            resolve(result)
          }
        })

        onError((error) => {
          queryLoading.value = false
          console.error(error)
          reject(error)
        })
      } catch (e) {
        queryLoading.value = false
        console.error(e)
        reject(e)
      }
    })
  }

  return {
    gqlQuery,
    queryLoading,
  }
}
