<template>
  <div
    class="menu-items group relative items-center"
    :class="`${hasSub(item)} ${
      device === 'mobile' ? 'mobile-subnav-root' : ''
    }`">
    <template v-if="item.cssClasses.includes('has-subnav')">
      <template v-if="item.uri !== '#'">
        <template v-if="device === 'mobile'">
          <div class="submenu-text-container">
            <quick-link
              class="mobile-subnav-parent lg:mb-0"
              :to="convertToRelative(item.uri)"
              >{{ item.label }}</quick-link
            >
          </div>
          <div
            class="submenu-trigger"
            @click="openSubnav">
            <img
              class="caret"
              :class="subnavOpen ? 'transform rotate-180' : ''"
              src="@/assets/img/caret-down.svg"
              alt="dropdown-caret" />
          </div>
        </template>
        <template v-else>
          <quick-link
            class="desktop-subnav-parent mb-3 lg:mb-0"
            :to="convertToRelative(item.uri)">
            {{ item.label }}
            <img
              class="caret ml-2"
              :class="sticky ? 'sticky' : ''"
              src="@/assets/img/caret-down.svg"
              alt="dropdown-caret" />
          </quick-link>
        </template>
      </template>
      <template v-else>
        <div class="desktop-subnav-parent mb-0 lg:mb-3">
          {{ item.label }}
          <div
            class="ml-2 transform group-hover:rotate-180 transition-transform ease-in-out flex items-center chevron"
            :class="{ 'rotate-180': subnavOpen }">
            <svg
              width="13px"
              height="6px"
              viewBox="0 0 13 6"
              version="1.1"
              stroke="inherit">
              <g
                stroke="inherit"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="0 0">
                <g
                  transform="translate(-1074.000000, -93.000000)"
                  stroke="inherit">
                  <g transform="translate(711.000000, 86.000000)">
                    <g transform="translate(304.000000, 0.000000)">
                      <path d="M60,7 L65.5,13 L71,7" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <template v-if="device !== 'desktop'">
        <Vue3SlideUpDown
          v-model="subnavOpen"
          class="subnav-accordion">
          <ul
            v-if="item.childItems.nodes.length > 0"
            class="sub-menu">
            <template
              v-for="sub in item.childItems.nodes"
              :key="sub.id">
              <li :title="sub.label">
                <template v-if="isExternal(sub.uri)">
                  <a
                    :href="sub.uri"
                    :target="sub.target"
                    :title="sub.label"
                    @click="closeNav"
                    >{{ sub.label }}</a
                  >
                </template>
                <template v-else>
                  <quick-link
                    :to="convertToRelative(sub.uri)"
                    @click="closeNav"
                    >{{ sub.label }}</quick-link
                  >
                </template>
              </li>
            </template>
          </ul>
        </Vue3SlideUpDown>
      </template>
      <template v-else>
        <ul
          v-if="item.childItems.nodes.length > 0"
          class="sub-menu">
          <template
            v-for="sub in item.childItems.nodes"
            :key="sub.id">
            <li
              :title="sub.label"
              :class="arrayToString(sub.cssClasses)">
              <template v-if="isExternal(sub.uri)">
                <a
                  :href="sub.uri"
                  :class="arrayToString(sub.cssClasses)"
                  :target="sub.target"
                  :title="sub.label"
                  >{{ sub.label }}</a
                >
              </template>
              <template v-else>
                <quick-link
                  :to="convertToRelative(sub.uri)"
                  @click="closeNav"
                  >{{ sub.label }}</quick-link
                >
              </template>
            </li>
          </template>
          <div class="triangle"></div>
        </ul>
      </template>
    </template>
    <template
      v-else-if="item.cssClasses.includes('CTA') && !device === 'mobile'">
      <quick-link
        :to="convertToRelative(item.uri)"
        exact
        @click="closeNav">
        {{ item.label }}
        <svg
          class="ml-2"
          width="17"
          height="8"
          viewBox="0 0 17 8"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.844 4.18558C17.0393 3.99032 17.0393 3.67374 16.844 3.47848L13.6621 0.296497C13.4668 0.101235 13.1502 0.101235 12.955 0.296497C12.7597 0.491759 12.7597 0.808342 12.955 1.0036L15.7834 3.83203L12.955 6.66046C12.7597 6.85572 12.7597 7.1723 12.955 7.36757C13.1502 7.56283 13.4668 7.56283 13.6621 7.36757L16.844 4.18558ZM0.925781 4.33203H16.4905V3.33203H0.925781V4.33203Z" />
        </svg>
      </quick-link>
    </template>
    <template v-else>
      <quick-link
        :to="convertToRelative(item.uri)"
        exact
        @click="closeNav"
        >{{ item.label }}</quick-link
      >
    </template>
  </div>
</template>

<script setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import useUtils from '@/composables/useUtils.js'
import { ref, watch } from 'vue'

const { device, scrollY, convertToRelative, isExternal } = useUtils()
defineProps(['item'])
const subnavOpen = ref()
const sticky = ref()

watch(scrollY, (newVal) => {
  if (newVal > 50) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

const hasSub = (item) => {
  return item.cssClasses.includes('has-subnav')
    ? `parent-nav ${arrayToString(item.cssClasses)}`
    : `${arrayToString(item.cssClasses)}`
}
const openSubnav = () => {
  subnavOpen.value = !subnavOpen.value
}

const closeNav = () => {
  subnavOpen.value = false
}

const arrayToString = (array) => {
  return array.join(' ')
}
</script>

<style lang="scss">
.caret {
  @apply transition duration-500 filter lg:brightness-100;
}

.menu-items {
  @apply w-full px-0 mx-0;
  @apply flex flex-col py-0 lg:py-0 md:pb-5 relative fill-white stroke-white;
  &.has-subnav {
    @apply flex;
    .mobile-subnav-parent {
      @apply w-max;
    }
    .submenu-text-container {
      @apply block py-2;
    }
    .submenu-trigger {
      @apply z-1 top-2 lg:top-0 -right-0 lg:-right-10 absolute h-[47px] w-full;
      .caret {
        @apply ml-2 absolute top-[22px] right-[25px];
      }
    }
  }
  &.hide-desktop {
    display: flex;
    @include desktop {
      display: none !important;
    }
  }

  a {
    @apply text-lm_white text-[40px] font-normal leading-[50px]
    opacity-100 border-b-lm_white border-b lg:border-b-0 pl-2
    w-full duration-200 transition ease-in-out self-center py-4 lg:py-0 relative z-10;
    &:before {
      @apply opacity-0 duration-500 transition ease-in-out content-[''] absolute w-3 h-3 bg-[#E6D1BB] blur-[2px] rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
    }
    &:after {
      @apply opacity-0 duration-500 transition ease-in-out content-[''] absolute w-3 h-3 bg-lm_white blur-md rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
    }

    &:hover {
      &:before {
        @apply opacity-100 duration-500 transition ease-in-out content-[''] absolute w-3 h-3 bg-[#E6D1BB] blur-[2px] rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
      }
      &:after {
        @apply opacity-100 duration-500 transition ease-in-out content-[''] absolute w-3 h-3 bg-lm_white blur-md rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
      }
    }
  }
  a.router-link-active {
    @apply opacity-100 text-lm_white;
    &:before {
      @apply opacity-100 duration-500 transition ease-in-out content-[''] absolute w-4 h-4 bg-[#E6D1BB] blur-[2px] rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
    }
    &:after {
      @apply opacity-100 duration-500 transition ease-in-out content-[''] absolute w-4 h-4 bg-lm_white blur-md rounded-full bottom-[calc(50%-8px)] lg:-bottom-[50px] left-auto lg:left-[calc(50%+8px)] right-2 lg:right-0 transform -translate-x-1/2;
    }
  }
  .subnav-accordion {
    @apply w-full;
  }
}

.desktop-subnav-parent {
  @apply flex flex-row justify-center opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
  .chevron {
    stroke: inherit;
    svg {
      stroke: inherit;
    }
  }
}

.mobile-subnav-parent {
  @apply inline-block opacity-60 hover:opacity-100 transition-opacity duration-200;
  stroke: inherit;
}

.mobile-subnav-root {
  img {
    &::before {
      @apply content-[''] absolute w-full h-full bg-green-500;
    }
  }
}

li.CTA {
  @apply lg:p-0;
  a {
    @apply lg:p-3 lg:px-6 lg:flex lg:items-center lg:content-center;
    svg {
      fill: inherit;
      stroke: none;
    }
  }
}
.sub-menu {
  @apply relative flex flex-col
      lg:absolute lg:top-0
      lg:right-[-15px] lg:transform lg:translate-y-[-999999px]
     lg:bg-white transition-all md:shadow-xl xl:max-w-[800px] w-full xl:w-max;
  li {
    padding: 0 !important;
    @apply relative  w-full flex xl:justify-start text-center lg:text-left text-black 
        border-b-[1px] border-white/10
        lg:w-auto lg:border-black lg:border-b lg:border-opacity-10 py-4;
    &:last-child {
      @apply border-none pb-0;
    }
    a {
      @apply w-full flex justify-center  small lg:text-black  lg:pb-0 lg:p-10 lg:pr-16 lg:last:py-3;
    }
  }
  &:hover {
    li {
      a {
        @apply text-gray-800;
        &:hover {
          @apply bg-black text-white;
        }
      }
    }
  }
}
@media all and (min-width: 1024px) {
  ul.sub-menu .triangle {
    content: '';
    position: absolute;
    top: -10px;
    right: 12px;
    height: 10px;
    width: 10px;
    background-color: #ffffff00;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9edee;
    clear: both;
    transform: rotate(180deg);
  }
  .sub-menu > li:nth-child(1):hover ~ .triangle {
    border-top: 10px solid rgba(0, 181, 223, 1);
  }
}

.parent-nav:hover .sub-menu {
  @apply lg:opacity-100 min-w-max transition-opacity duration-[0.5s] ease-in-out lg:transform lg:translate-y-[86px];
}
</style>
