<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <div
        ref="animation"
        class="intro-animation"></div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
//import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json'
import useIntroShown from '@/composables/useIntroShown.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import useSmoothScroll from '@/composables/useSmoothScroll.js'

const { setIntroComplete } = useIntroShown()
const { locoScroll } = useSmoothScroll()
const animation = ref()

onMounted(() => {
  locoScroll.stop()
  document.documentElement.classList.add('no-overflow')
  const anim = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: true,
    loop: false,
    animationData: introJson,
  })
  anim.setSpeed(0.7)
  anim.onComplete = () => {
    document.documentElement.classList.remove('no-overflow')
    locoScroll.start()
    setIntroComplete()
  }
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-[51] bg-gradient-to-tr from-lm_purple to-[#100d5f];
  .intro-container {
    @apply left-0 w-[100vw] h-[100vh] flex items-center justify-center bg-cover bg-no-repeat relative top-[-50px];
    .intro-animation {
      @apply w-full h-auto md:w-[800px] md:h-[800px] m-auto;
    }
  }
}
</style>
