import { ref } from 'vue'

const ready = ref(false)

const useReady = () => {
  const setReady = (val) => {
    ready.value = val
  }

  return { ready, setReady }
}

export default useReady
