const routeList = window.__VUE_WORDPRESS__.routing.routes

const importedComponents = [
  {
    name: 'Blog',
  },
  {
    name: 'Page',
  },
  {
    name: '404Page',
  },
  {
    name: 'SingleTeam',
  },
  {
    name: 'SinglePost',
  },
]

for (var key in routeList) {
  let route = routeList[key]
  let component_name = route.component
  const component = importedComponents.find((x) => {
    return x.name === component_name
  })
  if (component)
    routeList[key].component = () =>
      import(`../components/${component_name}.vue`)
  else {
    if (typeof window.__IS_DEV__ !== 'undefined') {
      console.error(
        'Developer: Please create a component named ' +
          component_name +
          ' and add it to the router array'
      )
    }
  }
}

const routes = routeList

export default routes
